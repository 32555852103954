import React from 'react'
import Layout from '@/components/layout'
import TermsOfUse from '@/components/PrivacyPolicy&TermsOfUse/TermsOfUse'
import { Footer } from '@/components/Footer'
import { graphql, useStaticQuery } from 'gatsby'

const TermsOfUsePage: React.FC = () => {
  const {
    allContentfulSubPagesTitles: { nodes },
  } = useStaticQuery(graphql`
    {
      allContentfulSubPagesTitles {
        nodes {
          termsOfUsePage
        }
      }
    }
  `)

  const { termsOfUsePage } = nodes[0]
  return (
    <Layout title={termsOfUsePage}>
      <TermsOfUse />
      <Footer />
    </Layout>
  )
}

export default TermsOfUsePage
